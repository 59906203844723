<template>
  <div v-if="userData">
    <b-card title="User Withdrawa" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- amount -->
            <b-col cols="6">
              <b-form-group label="Amount" label-for="Amount">
                <validation-provider
                  #default="{ errors }"
                  name="Amount"
                  :rules="
                    'required|regex:^[0]*[1-9][0-9]*$|between:1,' +
                    userData.wallet_amount
                  "
                >
                  <b-form-input
                    id="Amount"
                    v-model="data.amount"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- note -->
            <b-col md="6">
              <b-form-group label="Note" label-for="Note">
                <b-form-input id="Note" v-model="data.note" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="File" label-for="file">
                <input
                  type="file"
                  multiple="multiple"
                  class="form-control-file"
                  name="file"
                  id="file"
                  ref="file"
                  change
                  @change="fileUpload"
                  accept="application/*"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'

import { required } from '@validations'

export default {
  mixins: [heightTransition],

  data() {
    return {
      errors_back: [],
      showDismissibleAlert: false,
      required,
      files: null,
      data: {
        amount: '',

        note: '',
      },
      userData: null,
    }
  },
  created() {
    axios
      .get('users/' + this.$route.params.id + '/show')
      .then((result) => {
        this.userData = result.data.data
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let formData = new FormData()
          formData.append('amount', this.data.amount)
          formData.append('note', this.data.note)
          if (this.files != null) {
            formData.append('file', this.files)
          }

          axios
            .post(
              'users/' + this.$route.params.id + '/wallet/withdrawal',
              formData
            )
            .then((result) => {
              //console.log(result);
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    fileUpload(e) {
      this.files = e.target.files[0]
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    // basic
  },
}
</script>

